import * as React from "react";
import styles from './Logo.module.css';

function Logo(props) {
  return (
    <div className={styles.logo}>
      ResumeMatchPro
    </div>
  );
}

export default Logo; 